<template>
  <!-- 文件预览 -->
  <base-dialog
    :visible.sync="currentVisible"
    width="1200px"
    :showFooter="false"
    title="预览"
  >
    <base-button label="文件下载" v-if="dialogType=='list'" icon='iconfont iconxiazai' style="margin-bottom: 10px;float: right;" @click="downloadFile(fileId)"></base-button>
    <pre-view :count="counts" :fileId="fileId" :fileType="fileType" :style="dialogType=='list'?'height:calc(100% - 48px)':''"></pre-view>
    <template slot="footer">
      <!-- <base-button label='保 存'></base-button>
      <base-button label='提 交'></base-button> -->
      <base-button
        label="关 闭"
        type="default"
        @click="currentVisible = false"
      ></base-button>
    </template>
  </base-dialog>
</template>
<script>
import PreView from '@/components/pre-view/pre-view.vue'
import baseButton from '@/components/common/button/base-button/base-button.vue'
import BaseDialog from '@/components/common/base-dialog/base-dialog.vue'
import { downAPi } from '@/api/fileApi'
import { downFile } from '@/utils/devUtils'
export default {
  name: 'previewDialog',
  components: { BaseDialog, baseButton, PreView },
  props: {
    previsible: Boolean,
    fileId: String,
    fileType: String,
    count: Number,
    dialogType: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      counts: 0
    }
  },
  watch: {
    previsible: {
      handler (val) {
        this.$nextTick(() => {
          this.counts++
        })
      }
    }
  },
  computed: {
    currentVisible: {
      get () {
        return this.previsible
      },
      set (val) {
        this.$emit('update:previsible', val)
      }
    }
  },
  methods: {
    downloadFile (fileId) {
      if (fileId) {
        const params = { keyId: fileId }
        downAPi.download(params).then(res => {
          downFile(res)
        }).catch(e => {
          this.error(e)
        })
      }
    }
  }
}
</script>
