// 表格table
export const dataTable = (context) => {
  return [
    {
      type: 'selection',
      width: '50px',
      isHidden: true // 先隐藏了。暂时不上
    },
    {
      label: '序号',
      prop: 'code',
      width: '80px'
    },
    {
      label: '文件名称',
      prop: 'docName',
      'show-overflow-tooltip': true,
      align: 'left',
      'min-width': '150px'
    },
    {
      label: '文件名',
      prop: 'fileName',
      'show-overflow-tooltip': true,
      align: 'left',
      'min-width': '200px'
    },
    {
      label: '盖章类别',
      prop: 'name',
      isHidden: context.sealType === '0',
      'min-width': '100px'
    },
    {
      label: '盖章类型',
      prop: 'signerType',
      isHidden: context.sealType === '0',
      formatter: row => {
        const [obj] = context.signerType.filter(item => item.dictId === row.signerType)
        return obj ? obj.dictName : ''
      }
    },
    {
      label: '姓名',
      prop: 'personnelName',
      isHidden: context.sealType === '0'
    },
    {
      label: '电话号码',
      prop: 'mobilePhoneNo',
      isHidden: context.sealType === '0'
    },
    {
      label: '职位',
      prop: 'dutiesName',
      isHidden: context.sealType === '0'
    },
    {
      label: '人员类别',
      prop: 'personType',
      formatter: row => {
        if (row.personType) {
          const personTypeArr = row.personType.split(',')
          let valueArr = []
          personTypeArr.forEach((elem) => {
            const [obj] = context.personType.filter(item => item.dictId === elem)
            if (obj) {
              valueArr.push(obj.dictName)
            }
          })
          valueArr = valueArr.join(',')
          return valueArr || ''
        } else {
          return ''
        }
      },
      isHidden: context.sealType === '0'
    },
    {
      label: '通知状态',
      prop: 'noticeStatus',
      formatter: row => {
        const [obj] = context.noticeStatus.filter(item => item.dictId === row.noticeStatus)
        return obj ? obj.dictName : ''
      },
      isHidden: context.sealType === '0',
      width: '100px'
    },
    {
      label: '认证状态',
      prop: 'signStep',
      formatter: row => {
        return context.changeSignStep(row)
      },
      isHidden: context.sealType === '0',
      width: '100px'
    },
    {
      label: '签约状态',
      prop: 'signStatus',
      formatter: row => {
        return context.changeSignStatus(row)
      },
      width: '100px'
    },
    {
      label: '短链接',
      prop: 'msgLinkAddress',
      width: '100px'
    },
    {
      label: '电子章有效个数/需盖个数',
      prop: 'fileCount',
      'show-overflow-tooltip': true,
      formatter: row => {
        return (row.totalCount ? row.validCount + '/' + row.totalCount : '--')
      }
    },
    {
      label: '操作',
      prop: 'action',
      width: '100px',
      HiddenOverflow: true
    }
  ]
}
